<template>
  <div>
    <h3>Dashboard</h3>
    <loading v-if="loading" />
    <template v-if="!loading">
      <div class="row mt-3">
        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-primary shadow py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-uppercase mb-1"
                  >
                    <router-link class="text-primary" :to="{ name: 'admin.payments.index' }">Earnings <small>(This month)</small></router-link>
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">
                    <small class="text-tiny">KES</small> {{ stats.earnings }}
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fa fa-money fa-2x text-gray-300 text-primary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-success shadow py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-uppercase mb-1"
                  >
                    <router-link class="text-success" :to="{ name: 'admin.properties.index' }">Pending Properties</router-link>
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{ stats.pending }}</div>
                </div>
                <div class="col-auto">
                  <i class="fa fa-warning fa-2x text-success"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Earnings (Monthly) Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-info shadow py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-uppercase mb-1"
                  >
                    <router-link class="text-info" :to="{ name: 'admin.agencies.index', query: { filter: 'pending' }}">Pending Agencies</router-link>
                  </div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                      <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        {{ stats.agencies }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <i class="fa fa-clipboard fa-2x text-info"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Pending Requests Card Example -->
        <div class="col-xl-3 col-md-6 mb-4">
          <div class="card border-left-warning shadow py-2">
            <div class="card-body">
              <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                  <div
                    class="text-xs font-weight-bold text-uppercase mb-1"
                  >
                    <router-link class="text-warning" :to="{ name: 'admin.users.index' }">Registered Users</router-link>
                  </div>
                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{ stats.users }}</div>
                </div>
                <div class="col-auto">
                  <i class="fa fa-users fa-2x text-warning"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-7">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                <router-link :to="{ name: 'admin.payments.index' }" class="btn btn-sm btn-primary float-end">All Payments</router-link>
                Revenue <small class="text-muted" style="font-size: 16px;">Last 30 days</small>
                <chart :data="graph_data" type="line" class="mt-3" height="200" />
              </h4>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Posted Properties</h5>
              <div class="row mb-2">
                <div class="col-md-5">
                  <label>Date From</label>
                  <date-picker input-class="form-control" placeholder="Date From" v-model="post_stats.date_from" />
                </div>
                <div class="col-md-5">
                  <label>Date To</label>
                  <date-picker input-class="form-control" placeholder="Date From" v-model="post_stats.date_to" />
                </div>
                <div class="col-md-2">
                  <label class="text-white d-block">.</label>
                  <a href="#" class="btn btn-primary px-2" @click.prevent="fetchPostStats"><i class="lni lni-search"></i></a>
                </div>
              </div>
              <chart :data="post_stats.graph" type="pie" class="mt-3" height="150" v-if="! posts_stats_loading" />
              <loading v-if="posts_stats_loading" message="Wait..." />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-5">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Latest Paid Posts</h4>
              <table class="table table-striped mt-3">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="properties.length == 0">
                    <td colspan="8">There is no data to display</td>
                  </tr>
                  <tr v-for="(property, i) in properties" :key="`property-${i}`">
                    <td>{{ i+1 }}</td>
                    <td>
                      <router-link :to="{ name: 'admin.properties.show', params: { id: property.id }}">{{ property.name }}</router-link>
                    </td>
                    <td>{{ property.type }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Chart from "@/components/Chart";
import moment from 'moment'

export default {
  components: {
    Chart
  },

  mounted() {
    this.fetch();
    this.fetchPostStats()
  },

  data() {
    return {
      loading: true,
      stats: {},
      graph_data: {},
      properties: [],
      post_stats: { date_from: null, date_to: null, graph: {} },
      posts_stats_loading: true
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.axios.get("/api/v1/admin/dashboard").then((response) => {
        this.stats = response.data.stats;
        this.graph_data = response.data.graph_data;
        this.properties = response.data.properties;
        this.loading = false;
      });
    },

    fetchPostStats() {
      this.posts_stats_loading = true
      let date_from = this.post_stats.date_from == null ? '' : moment(this.post_stats.date_from).format('YYYY-MM-DD')
      let date_to = this.post_stats.date_to == null ? '' : moment(this.post_stats.date_to).format('YYYY-MM-DD')

      this.$axios.get(`/api/v1/admin/dashboard/post-stats?date_from=${date_from}&date_to=${date_to}`).then(response => {
        this.post_stats = response.data.post_stats
        this.posts_stats_loading = false
      })
    }
  },
};
</script>
